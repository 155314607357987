const Texts = {
  PT: {
    title: 'Game Jam Plus 21/22',
    text: `
      <p class="strong">Nossas boas vindas a Game Jam Plus 2021!</p>
      <p>A Game Jam Plus é um movimento colaborativo, sem fins lucrativos, de fomento ao empreendedorismo na indústria de economia criativa. Somos uma maratona e aceleração de desenvolvimento de jogos e gamificação que tem como objetivo transformar jogos em negócios.</p>
      <p>A edição 2021 da copa do mundo de desenvolvimento de jogos contará com <span class="strong">4 categorias</span>:</p>
      <ol>
        <li>
          <p>
            <span class="strong">Desafio Anjo Tintas:</span> a marca brasileira de tintas Anjo Tintas patrocina essa categoria dedicada ao melhor jogo que compactua com as missões e objetivos da empresa;
          </p>
        </li>
        <li>
          <p>
            <span class="strong">Educação:</span> categoria que premia o melhor jogo com foco em ensino, conteúdo didático e aprendizagem;
          </p>
        </li>
        <li>
          <p>
            <span class="strong">Diversidade:</span> categoria que elege o melhor jogo com abordagem, referência ou temática relacionada às maiorias minorizadas, como mulheres, pessoas pretas, indígenas, público LGBTQIA+ ou PcD;
          </p>
        </li>
        <li>
          <p>
            <span class="strong">Categorias técnicas:</span> categoria tradicional dividida em outras subcategorias. São analisados e premiados os melhores jogos em categorias como: Melhor Gameplay, Melhor Direção de Arte, Jogo do Ano, entre outras.
          </p>
        </li>
      </ol>
    `,
    instrucoes: `Como participo da Game Jam Plus?`,
    instrucoesText: `
      <p>
        Encontre a sede mais próxima de você e registre-se na plataforma Eventbrite <a href="https://www.eventbrite.com.br/o/game-jam-plus-21753100209" target="_blank">clicando aqui ou no botão "Inscreva-se"</a>, e aguarde pelo e-mail de confirmação. A organização local, onde foi registrado seu cadastro, irá entrar em contato para as demais orientações.
      </p>
      <p>Caso não tenha equipe formada ou esteja procurando por mais membros para o seu time:</p>
      <ul>
        <li>Entre em contato com o seu organizador local para informações sobre alguma dinâmica de formação de grupos antes do início do evento ou indicação de outros participantes que não estão com equipe completa;</li>
        <li>No início do evento, dia 8 de outubro, você também poderá encontrar outros participantes que não possuem grupo fechado para formar sua equipe.</li>
      </ul>
      <small>Atenção: As inscrições acontecem até o dia 8 de outubro. </small>
    `,
    participar: `Como funciona a Game Jam Plus?`,
    participarText: `
      <p>Uma vez iniciada a competição, saiba mais sobre as 4 etapas que constituem a Game Jam Plus:</p>
      <ul>
        <li>
          <p><span class="strong">Primeira Etapa -</span> Jams simultâneas em mais de 15 países no mundo.</p>
          <p><span class="strong">Data:</span> 8 a 10 de Outubro.</p>
          <p>Seguindo um formato já conhecido de game jams, os participantes terão 48h para elaborar e desenvolver um jogo do zero! Após o desenvolvimento, o jogo será avaliado por uma banca de jurados especialistas. Além disso, da mesma forma que se faria uma apresentação do projeto para grandes investidores, os grupos devem desenvolver um pitch de até 5 minutos focado em descrever o plano de negócios de seu jogo, com detalhes sobre como pretendem distribuir, monetizar e gerir seu projeto.</p>
          <p><span class="strong">Instruções:</span></p>
          <ul>
            <li>
              A build dos jogos deve ser submetida na página do evento no itch.io até às 23:59 do dia 10/10 (fuso horário local);
            </li>
            <li>
              O vídeo de pitch (com até 5 minutos) deve ser enviado para o YouTube em formato público ou não listado até às 23:59 do dia 12/10 (fuso horário local);
            </li>
            <li>
              Cada projeto deverá ser submetido em um formulário do Google Docs contendo as informações básicas sobre o jogo, informações da equipe, sede participante, link do itch.io, link do pitch no YouTube, entre outras informações importantes para o registro de seu projeto em nossa competição.
            </li>
            <li>
              Atenção! O jogo submetido no itch.io não garante participação na competição caso não tenha sido preenchido o formulário citado acima!
            </li>
          </ul>
        </li>
        <li>
          <p><span class="strong">Segunda Etapa -</span> Mentorias online com profissionais de diversas áreas, como: programação, negócios, marketing, arte, som e etc. Todos profissionais encontram-se ativos no mercado nacional e internacional, com alto nível de expertise. Esta fase tem como objetivo auxiliar todas as equipes a melhorarem seus protótipos criados na primeira etapa.</p>
          <p><span class="strong">Data:</span> Novembro, Dezembro, Janeiro e Fevereiro.</p>
        </li>
        <li>
          <p><span class="strong">Terceira Etapa -</span> As equipes campeãs da primeira etapa serão, pela primeira vez, colocadas frente a frente em cinco Finais Continentais. Apenas os melhores projetos de cada região do mundo seguirão para a próxima etapa.</p>
          <p><span class="strong">Data:</span> Março de 2022.</p>
        </li>
        <li>
          <p><span class="strong">Quarta Etapa -</span> A Grande Final</p>
          <p>É o momento em que as equipes classificadas e organizadores de todo o mundo se encontram presencialmente no Rio de Janeiro (Brasil), a fim de definir e celebrar os grandes vencedores. Esta etapa irá proporcionar momentos únicos de networking, trocas de experiência e claro, muitos jogos.</p>
          <p><small>Observação: todas as despesas com passagem e hospedagem são de responsabilidade do participante. Confira com o seu organizador local se existe algum tipo de patrocínio.</small></p>
          <p><span class="strong">Data:</span> 30 de Junho, 1, 2 e 3 de Julho.</p>
        </li>
      </ul>
      <small>Lembre-se de ler atentamente todas as informações repassadas por seu organizador local durante toda a competição!</small>
    `,
    premiacoes: `O que você ganha por participar da Game Jam Plus?`,
    premiacoesText: `
      <p>Além de participar dessa incrível competição global e entrar na disputa de todos os prêmios, você:</p>
      <ul>
        <li>
          Ajuda a fomentar o ecossistema de desenvolvimento de games no Brasil e no mundo;
        </li>
        <li>
          Ganha acesso em 50% das nossas palestras vip na segunda etapa;
        </li>
        <li>
          Recebe certificado de participação da GJ+ 2021 com 60 horas complementares (primeira etapa) e 120 horas (até a quarta etapa);
        </li>
        <li>
          Acesso a nossa comunidade global de desenvolvedores e empreendedores, gerando oportunidades de crescimento profissional através de network, oportunidade de feedbacks dos projetos, contato com vagas especiais no mercado gamedev internacional, etc.
        </li>
      </ul>
    `,
    pagar: `Por que pagar pela GJ+?`,
    pagarText: `
      <p>A força do nosso movimento está no poder da colaboração (são quase 1000 voluntários no mundo). Poderíamos esperar algum governo ou empresa privada financiar as passagens e hospedagens para final, mas entendemos que o cidadão é o principal agente de transformação do meio em que vive e, assim, entendemos que os participantes que têm poder aquisitivo* poderiam também contribuir com a causa.</p>
      <p>É fundamental destacar que 100% da receita é revertida para ajudar os finalistas a irem à final. Isto é, vocês pagam e se beneficiam diretamente, caso sejam finalistas.</p>
      <p><small>*O objetivo da cobrança não é excluir ninguém. Se o ingresso estiver em um valor totalmente inviável para a sua contribuição, entre em contato com o organizador local da sua cidade e verifique a disponibilidade de gratuidade.</small></p>
    `,
    button: 'Inscreva-se',
    buttonLink: 'https://www.eventbrite.com.br/o/game-jam-21753100209',
  },
  EN: {
    title: 'Game Jam Plus 21/22',
    text: `
      <p class="strong">Welcome to Game Jam Plus 2021!</p>
      <p>Game Jam Plus is a non-profit collaborative movement that promotes the economy in the creative industry. We are a game development marathon and development accelerator that aims to turn games into business.</p>
      <p>The 2021 edition of the game development world cup will have <span class="strong">4 categories:</span></p>
      <ol>
        <li>
          <p>
            <span class="strong">Anjo Tintas Challenge:</span> the brazilian brand Anjo Tintas sponsors this category in search for the best game that best speaks to the company’s goals and missions;
          </p>
        </li>
        <li>
          <p>
            <span class="strong">Education:</span> category that award the best game focused on education, learning and educational content;
          </p>
        </li>
        <li>
          <p>
            <span class="strong">Diversity:</span> category that elects that best game with a approach, reference or theme related to minorities such as women, black people, LGBTQIA+ public, PwD or other ethnic groups;       
          </p>
        </li>
        <li>
          <p>
            <span class="strong">Technical categories:</span> regular category divided into subcategories such as: Best Game Play, Best Art Direction, Game of the Year and many others.
          </p>
        </li>
      </ol>
    `,
    instrucoes: `How do I participate in Game Jam Plus?`,
    instrucoesText: `
      <p>
      Find your nearest headquarters and register at Eventbrite by <a href="https://www.eventbrite.com.br/o/game-jam-plus-21753100209" target="_blank">clicking here or at the “Register” button</a>, and wait for the confirmation email. Your local organization will reach you for further details. 
      </p>
      <p>In case you don’t have a team or are looking for member for your team:</p>
      <ul>
        <li>Get in touch with your local organizer for support or some dynamic for team formation before the event start, or even try to reach other participants that are looking for members;</li>
        <li>At the beginning of the jam, on October 8th, you can also find other participants that don't have a restricted team.</li>
      </ul>
      <small>Attention: registrations will be accepted until October 8th.</small>
    `,
    participar: `How does Game Jam Plus work?`,
    participarText: `
      <p>Once the jam start, get to know more about the four stages of Game Jam Plus:</p>
      <ul>
        <li>
          <p><span class="strong">First Stage -</span> Simultaneous jams in over 15 countries around the world.</p>
          <p><span class="strong">When:</span> October 8th to 10th</p>
          <p>Following an already known game jam format, participants will have 48 hours to develop a game from scratch. After the development the game will be reviewed by a team of specialists. Besides, just as in a marketing action or a big company pitching process, you will have to create your own pitch for your game.</p>
          <p><span class="strong">Instructions:</span></p>
          <ul>
            <li>
              The game builds must be uploaded at the jam page on itch.io by October 10th, at 11:59 pm (local time zone);
            </li>
            <li>
              The game pitch (5 minutes max.) must be delivered by October 12th, at 11:59 pm (local time zone);
            </li>
            <li>
              Each project must be delivered in a Google Forms with information about the game, the team, the headquarters, the game link and the pitch link;
            </li>
            <li>
              Attention! The game uploaded on itch.io does not guarantee your participation. The Google Form is also mandatory!
            </li>
          </ul>
        </li>
        <li>
          <p><span class="strong">Second Stage -</span> Online mentoring sessions with renowned professionals for areas such as: programming, marketing, business, art, sound and others. All professionals are currently on the international market and have a high expertise level.</p>
          <p><span class="strong">When:</span> November, December and January.</p>
        </li>
        <li>
          <p><span class="strong">Third Stage -</span> The First Stage finalists will compete at five different Region Finals. Only the best projects from each region will go on to the next stage.</p>
          <p><span class="strong">When:</span> March 2022.</p>
        </li>
        <li>
          <p><span class="strong">Fourth Stage -</span> The Big Final</p>
          <p>This is where the finalists and organizers all meet in Rio de Janeiro (Brazil) to define and celebrate the big Game Jam Plus winners. This stage will provide special moments of networking, experience exchange and, of course, lots of games!</p>
          <p><small>Note: all expenses are the responsibility of the participants. Check with your local organizer if there is any sort of support.</small></p>
          <p><span class="strong">When:</span> June 30th, July 1st, 2nd and 3rd.</p>
        </li>
      </ul>
      <small>Remember to read carefully all the information.</small>
    `,
    premiacoes: `What do you win by attending Game Jam Plus?`,
    premiacoesText: `
      <p>Besides joining  this amazing global competition and compete for all the prizes, you:</p>
      <ul>
        <li>
        Help to reinforce and strengthen the game development industry in Brazil and in the world;
        </li>
        <li>
        Win 50% access at our VIP workshops from the Second Stage;
        </li>
        <li>
        Get a official certificate for 60 hours (First Stage) and 120 hours (by Fourth Stage);
        </li>
        <li>
        Join our global community of developers and entrepreneurs, creating opportunities of professional growth through network, feedbacks on projects and special jobs opportunities in the international market.
        </li>
      </ul>
    `,
    pagar: `Why pay for GJ+?`,
    pagarText: `
      <p>The strength of our movement comes from the power of collaboration (there are over 1000 volunteers around the world). We could expect the government or a private company to finance all the flying tickets and hosting expenses to the Big Final, but we believe that the individual citizen is the final transformation agent where they live. Therefore, we believe that participants that can financially* afford the tickets can actually strengthen our cause. </p>
      <p>We always like to reiterate that 100% of the ticket incomes are reverted to helping finalists to travel and attend the finals.</p>
      <p><small>*We are not trying to exclude anyone. If the ticket price is out of your reach, try to talk with your local organizer and check if you can join for free.</small></p>
    `,
    button: 'Make Your Registration!',
    buttonLink: 'https://www.eventbrite.com.br/o/game-jam-21753100209',
  }
}

export default Texts